import React, {Component} from "react";
import { Modal, Button, Toast } from "antd-mobile";
import { inject, observer } from 'mobx-react';
import EnrollServers from '../services/EnrollServers';

@inject("enrollStore", "myEnrollStore")
@observer
class ManageDialog extends Component{
    state = {
        showVoucher: false,
        enrollList: [],
    };
    constructor(props){
        super(props); 
    };
    componentWillReceiveProps(props){     
        this.setState({showVoucher: props.showVoucher});   
    }  
    componentDidMount(){
        this.props.myEnrollStore.getMyEnroll(1, 100).then(res=>{   
            if(res.sta == 0){
                this.setState({enrollList: res.data});
            }
        })
    } 
    render(){
        let {detail} = this.props.enrollStore;
		return (
			<Modal title="我的凭证" visible={this.state.showVoucher} transparent={true} animationType="slide-up" popup className="vocher-data-dialog" onClose={()=>{
				this.props.close()		 
			}}>
                <div className="vocher-list">
                    {
                        this.state.enrollList.map((item, index)=>{
                            return (
                                <div className="vocher-items" key={index} onClick={()=>{
                                    if(item.verified == -1){
                                        Toast.info('排队中不可查看凭证');
                                    }else if(detail.verify && (item.verified == 0 || item.verified == 2 || item.verified == 3)){
                                        Toast.info('审核通过后方可查看凭证');
                                    }else{
                                        this.props.myEnrollStore.getEnrollCert(item.info_id).then(res=>{
                                            let {cert, records} = this.props.myEnrollStore;
                                            let params = {cert, records};
                                            sessionStorage.setItem('certInfo',JSON.stringify(params));                                         
                                            this.props.open();		                                            
                                        });    
                                    }
                                }}>
                                    <div>
                                        <div>{item.name}</div>
                                        <span className="cen-time">{EnrollServers.formatDate(item.join_time*1000, 'yyyy-MM-dd HH:mm:ss')}</span>
                                    </div>
                                   { this.renderButton(item, detail) }                       
                                </div>
                            )
                        })
                    }             
                </div>				 
				<Button className='cancel-btn' onClick={()=>{
					this.props.close() 				 
				}}>取消</Button>
			</Modal>
		)
    };
    renderButton(item, detail){       
        if(item.verified == -1){
            return <span className="gray-text">排队中</span>;
        }else if(detail.verify && item.verified == 0){
            return <span className="gray-text">待审核</span>;
        }else if(detail.verify && item.verified == 2){
            return <span className="gray-text">退回</span>;
        }else if(detail.verify && item.verified == 3){
            return <span className="gray-text">驳回</span>;
        }else{
            return <span className="view-btn">查看凭证 ></span>
        }          
    };
  
}


export default ManageDialog;