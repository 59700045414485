import React, {Component} from 'react';
import {Flex,ListView, Modal, Toast} from 'antd-mobile';
import icon_vip_img from '../../res/img/vip_sign.png'
import Config from '../../config/index';
import WxApi from '../../common/wxapi';
import {inject, observer} from 'mobx-react';
import ic_top_notice from "../../res/img/ic_detail_showmore.png"
@inject("enrollStore", "loadingStore")
@observer
class UserList extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let {getEnrollUserList ,detail} = this.props.enrollStore;
		if(detail && this.props.is_await != 1 && (detail.count <= Number(detail.user_list_limit) || detail.is_admin || detail.is_owner)){ //根据后台接口的阈值来限制加载
			getEnrollUserList.bind(this.props.enrollStore)(this.props.eid);
		}
    }
	
	getUserList(row) {
		let {userDataSource, getEnrollUserList, userListLoading, userListNumCount, detail,enrollUserList} = this.props.enrollStore;
	    if(enrollUserList.length > 0 ){
			return(
				<div>
				{
					userListNumCount != 0 ? <ListView
					    ref={el => this.lv = el}
					    dataSource={userDataSource}
					    renderFooter={() => (<div style={{textAlign: 'center'}}>
					        {userListLoading ? '正在加载...' : ''}
					    </div>)}
					    renderRow={row}
					    useBodyScroll={true}
					    scrollRenderAheadDistance={100}
					    onEndReached={() => {
							getEnrollUserList.bind(this.props.enrollStore)(this.props.eid)
						}}
					
					/> : <div className={`no-data-tips`}>还没有人参与</div>
				}
				</div>
			)
		}else{
	
			if(detail && detail.count == 0){
				return(
				<div className={`no-data-tips`}>还没有人参与</div>
				)
			}else{
				return(
					<div className={"view-more-list"} onClick={() => {
                        if(!Config.TOKEN){
                            React.$wx.webLogin();
                            return;
                        }
                        if(this.props.is_await != 1 ){
                            this.props.enrollStore.getEnrollUserList();
                        }else{
                            Toast.loading('数据加载中，请稍等')
                        }						
					}}>查看参与人列表<img className='show-more-icon' src={ic_top_notice} /></div>
					
				)
			}
		}
	};

    render() {
        let {userListNumCount, detail} = this.props.enrollStore;
        const row = (rowData, sectionID, rowID) => {
            let getStatue = function () {
                if (!!detail.verify) {
                    if (rowData.verified == 0) {
                        return (
                            <div className={"Detail-item-des1 blue"}>
                                待审核
                            </div>
                        )
                    }
                    else if (rowData.verified == 1) {
                        return (
                            <div className={"Detail-item-des1 gray"}>
                                通过
                            </div>
                        )
                    }
                    else if (rowData.verified == 2) {
                        return (
                            <div className={"Detail-item-des1 red"}>
                                退回
                            </div>
                        )
                    }else if(rowData.verified == -1){
						return (
						    <div className={"Detail-item-des1 blue"}>
						        排队中
						    </div>
						)
					}else if(rowData.verified == 3){
                        return (
						    <div className={"Detail-item-des1 red"}>
						        驳回
						    </div>
						)
                    }
                }else if(rowData.verified ==-1){
                    return (
                        <div className={"Detail-item-des1 blue"}>
                            排队中
                        </div>
                    )
				}
                else {
                    return (<div className="Detail-item-des1">
                        提交成功
                        {
                            rowData.refund && (detail.is_owner || detail.is_admin) ?<span className='refund-text'>({rowData.refund == 1?'已退款':'退款失败'})</span>:''
                        }
                    </div>)
                }
            };

            let getName = function () {
                if(detail.user_visible == 1){
                    return (<div className="Detail-item-name">
                                <span className={rowData.card_vip ? 'vip-name':''}>{WxApi.getDefaultName(rowData.name)}</span>
                                {rowData.on_behalf ? <span className="tag-info">多</span> : null}
                            </div>)
                }
                else{
                    return (<div className={rowData.card_vip ? 'vip-name Detail-item-name':'Detail-item-name'}>**</div>)
                }
            };
			             
            return (
                <Flex key={rowID} className="Detail-list-item">
                    <img className='user-avatar' src={rowData.head_img||Config.defaultAvatar}/>
                    {
                        rowData.card_vip ? <img className='vip-img' src={icon_vip_img} /> : ''
                    }
                    <Flex justify={"between"} style={{"width": "100%"}} className="Detail-list-flexpanel">
                        <Flex.Item style={{"width": "150px"}}>
                            {
                                getName()
                            }
                            <div className="Detail-item-time">
                                {rowData.date_str}
                            </div>
                        </Flex.Item>
                        <Flex.Item className="Detail-item-des">
                            {
                                getStatue()
                            }
							{
								detail.rank_switch ? <div className="Detail-item-des2">
                                编号: {rowData.rank_no}
                            </div> : <div className="Detail-item-des2">
                                No. {userListNumCount - rowID}
                            </div>
							}
                        </Flex.Item>
                    </Flex>
                </Flex>
            );
        };
        const detailStyle = {
            width: '100%',
            height: '44px',
            position: 'absolute',
            right: '0',
            top: '0',
        }
        return (
            <div className="Detail-content-member">               
                <div className='tab-menu'>
                    <span className='tab-items focus-tab' style={{color: this.props.themColor, borderBottom: '2px solid '+ this.props.themColor}}>已提交({userListNumCount <= 0?detail.count :userListNumCount})</span>
                    <span className='tab-items' onClick={()=>{
                        if(!WxApi.isWeixn()){
                            Modal.alert('温馨提示','目前仅报名工具小程序支持查看「详细数据」，请前往小程序去完成吧',[{ text: '我知道了' }])
                        }
                    }}>
                        { detail.visibility == 1 ? "详细数据" : '' }
                        {
                            detail.visibility == 1 && WxApi.isWeixn() ?
                            <wx-open-launch-weapp id="coupon-btn" username="gh_ae6449501a82" style={detailStyle} path={this.props.wxJumpPath}> 
                                <script type="text/wxtag-template">
                                    <div style={detailStyle}></div>
                                </script>
                            </wx-open-launch-weapp> : ''
                        }
                    </span>                    
                </div>
                <div className='msg-notification' onClick={()=>{
                    this.props.openMsg()                    
                }}>
                    <i className='notify-img iconfont icon-svg45-copy' style={{color: this.props.themColor}}></i>                     
                    <h2 className='notify-title'>订阅通知 <span className='notify-desc'>及时接收报名成功、取消等</span></h2>                   
                    <span className='notify-btn' style={{color: this.props.themColor}}>去订阅 ></span>
                </div>
                {
                    this.getUserList(row)                    
                }
            </div>
        )
    }
}

export default UserList;
