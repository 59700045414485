import HttpHelper from "../common/HttpHelper";

import BaseServers from "./BaseServers";
import Config from "../config";
import WxApi from "../common/wxapi";
import JSEncrypt from 'jsencrypt';

export default class HomeService extends BaseServers {

    static async getEnrollDetail(type) {
    	let detail_url = "";
    	if(Config.TOKEN){
            detail_url = Config.api_detail;               		
    	}else{
    		detail_url = Config.api_noLogin_detail; //未登录的活动详情接口
    	}
    	
        return HttpHelper.get(detail_url, {
            params:
                {
                    eid: Config.EID,
                    access_token: Config.TOKEN,
                    referer:Config.referer,
                    spider: 'h5',
                }
        });
    }

    // 详情填写字段接口
    static async getReqDetail() {         	
        return HttpHelper.get(Config.api_req_detail, {
            params:
                {
                    eid: Config.EID,
                    access_token: Config.TOKEN,                    
                }
        });
    }

    //活动详情中的多项目数据
	static async getItemDetail(data, temp){
        let params =  {
            eid: Config.EID,                   
            access_token: Config.TOKEN
        }
        if(data){
            params.reserve_month = data.reserve_month
            params.item_key = data.item_key
        }
        let url = Config.api_item_detail 
        if(temp == 10){ //选座用V1接口
            url = Config.api_seat_detail
        }
        return HttpHelper.get(url, {
            params               
        });
    }
    
    static async getEnrollUserList(lt) {
        return HttpHelper.get(Config.api_user_list, {
            params:
                {
                    eid: Config.EID,
                    info_id: lt,
                    count: Config.pageSize,
                    access_token: Config.TOKEN
                }
        });
    }

    static async getTeamList(team) {
        return HttpHelper.get(Config.team_list, {
            params:
                {
                    eid: Config.EID,
                    page: Config.pageCount,
                    count: Config.pageSize,
                    team: team,
                    access_token: Config.TOKEN || ""
                }
        });
    }

	//获取拼团结果详情
	static async getTeamDetail(tid) {
        return HttpHelper.get(Config.team_result_detail, {
            params:
                {
                    tid: tid,
                    access_token: Config.TOKEN
                }
        });
    }

    static async getMyEnroll(page,count) {
        return HttpHelper.get(Config.api_my_enroll, {
            params:
                {
                    eid: Config.EID,
                    access_token: Config.TOKEN,
                    page,
                    count,
                }
        });
    }

    static async getEnrollUserListByInfoId(info_id) {
        return HttpHelper.get(Config.api_user_detail, {
            params:
                {
                    eid: Config.EID,
                    info_id: info_id,
                    access_token: Config.TOKEN
                }
        });
    }

    static async saveEnrollInfo(info_id, info,items) {
        return HttpHelper.post(Config.api_update_enroll,
            {
                info_id: info_id,
                info: info,
                access_token: Config.TOKEN,
                items:items
            }
        );
    }

    static async exitEnroll(sub_mchid,info_id,reason,apply_reason) {
		let parm = {
			info_id: info_id,
			access_token: Config.TOKEN,
			role:0,
		}
		if(reason){
			parm.reason = reason;
            parm.apply_reason = apply_reason
		}
		
		return HttpHelper.post(Config.api_exit_enroll_merch,parm)
    }
	
	static async refundExitEnroll(info_id) {
		let parm = {
			info_id: info_id,
			access_token: Config.TOKEN,
		}
		return HttpHelper.post(Config.api_refun_exit,parm);
	}

    static async submitEnroll(info, on_behalf, item) {
        let encryptor = new JSEncrypt()  // 创建加密对象实例						
        const pubKey = `-----BEGIN PUBLIC KEY-----
            MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCjI8E8LT0fwFekelMMuTWuaIfo
            fK69lyNIo+Vz0CGdfE3rLSIH94S2A3Q+bg+9/VnImvfXzcDVmqwHwC4hHPHs6hc6
            ufq0gfivTPms3kwX74F5qLMr70j4iZLt/PCkU+uyQ56KmRW4foCV4RPX8o8QZVss
            6eifHaeUeJxKM556ewIDAQAB
            -----END PUBLIC KEY-----`
        encryptor.setPublicKey(pubKey)//设置公钥   
        return HttpHelper.post(Config.api_enroll,
            {
                eid: Config.EID,
                info: info,
                on_behalf: on_behalf ? 1 : 0,
                items: item,
                access_token: Config.TOKEN,
                referer:Config.referer,
                from:'h5',
                _a: encryptor.encrypt(Config.EID+Math.round(new Date().getTime()/1000)),
            }
        );
    }

    static async submitEnrollForPay(info, on_behalf, item,tid,sub_mchid,coupon_info_id) {
        if(info && Array.isArray(info)){
            info = info.map(item=>{ //避免逻辑设置时，填写了数据，但是后面又修改了选项，结果数据还是提交了
                if(item.ignore == 1){
                    if(item.field_value) item.field_value = '';
                    if(item.new_field_value)item.new_field_value = '';               
                }
                return item;
            })
        }
       
    	let parm = {
                eid: Config.EID,
                info: info,
                on_behalf: on_behalf ? 1 : 0,
                items: item,
                from: Config.bm_domain ? "xcx" : "enroll_h5_new",
                access_token: Config.TOKEN,
                referer:Config.referer,
            }

        if(!WxApi.isMobile() || !WxApi.isWeixn()){ //若为pc端，则调网页支付
            parm.pay_source = 'pc'
        }
  
        if(tid || tid==""){ //1、tid为空，代表拼团，2、tid有值，参团，3、无tid,单独购买
    		parm.tid = tid
    	}
        if(coupon_info_id){
            parm.coupon_info_id = coupon_info_id
        }
       
		if(sub_mchid){			
            parm.from = 'enroll_h5_new';
			return HttpHelper.post(Config.api_enroll_merch,parm);
		}else{
			return HttpHelper.post(Config.api_enroll,parm);
		}
        
    }
    
    //绑定商户支付分两步走，通过temp_id请求支付参数
    static async submitBusinessPay(tmp_id) {
        return HttpHelper.post(Config.api_enroll_pay,
            {
                access_token: Config.TOKEN,
                tmp_id:tmp_id,
            }
        );
    }

    static async getEnrollCert(info_id) {
        return HttpHelper.get(Config.api_cert_detail, {
            params: {
                    info_id: info_id,
                    access_token: Config.TOKEN
                }
            }
        );
    }
	
	static async getEnrollCustom() {
	    return HttpHelper.get(Config.api_cert_custom, {
	        params: {
	                eid: Config.EID,
	                access_token: Config.TOKEN
	            }
	        }
	    );
	}

    static async uploadImage(media_id) {
    	
        return HttpHelper.post(Config.api_upload_file, {
				source: Config.bm_domain == true ? 'h5' : '',
                media_id: media_id,
                access_token: Config.TOKEN

            }
        );
    }

    static async uploadBase(base64) {
        return HttpHelper.post(Config.api_upload_file, {
                bs64: base64,
                access_token: Config.TOKEN

            }
        );
    }

    static async getShare(type = 0) {
        return HttpHelper.get(Config.api_get_share, {
            params: {
                    eid: Config.EID,
                    type: type,
                    access_token: Config.TOKEN,
                    img_source:'h5'
                }
            }
        );
    }

    static async getHomePageShare(unionid) {
        return HttpHelper.get(Config.api_get_homepage_share, {
            params: {
                    unionid: unionid,
                }
            }
        );
    }

    static async subscribeHomepage(type, unionid) {
        return HttpHelper.post(Config.api_subscribe_homepage, {
            unionid: unionid,
            access_token: Config.TOKEN,
            switch: type,
        });
    }
    //收藏和取消收藏
    static async setCollect(type) {

        return HttpHelper.post(Config.api_collect_enroll, {
            eid: Config.EID,
            access_token: Config.TOKEN,
            fav:type? 0: 1,
        });
    }
    
    
    //提交举报信息
    static async submitReport(reason) {
    	
        return HttpHelper.post(Config.api_enroll_report, {
            eid: Config.EID,
            access_token: Config.TOKEN,
            reason:reason,
        });
    }
    
    
    //获取订阅消息场景值
    static async getSceneId() {
    	return HttpHelper.get(Config.api_scene_id, {
            params: {
                    eid: Config.EID,
            		access_token: Config.TOKEN
                }
            }
        );
    }
    
    //订阅消息
    static async subNotice(scene_id,openid) {
    	
    	 return HttpHelper.post(Config.api_scene_notice, {
            eid: Config.EID,
            access_token: Config.TOKEN,
            scene_id:scene_id,
            openid:openid,
        });
    }
    
     //口令验证
    static async subToken(token) {
    	 return HttpHelper.post(Config.api_enroll_token, {
            eid: Config.EID,
            access_token: Config.TOKEN,
            token:token,
        });
    }
    
	//获取留言列表
	static async getMessageList(type) {
	    return HttpHelper.get(Config.api_detail_message_list, {
	        params:
	            {
	                eid: Config.EID,
	                page: Config.pageCount,
	                count: Config.pageSize,
	                access_token: Config.TOKEN || "",
					type:type,
				}
			});
	}
	
	//添加留言
	static async addMessage(content,device,c_pic) {
	    return HttpHelper.post(Config.api_detail_message_list, {
				eid: Config.EID,
				access_token: Config.TOKEN || "",
				content,
                device,
                c_pic
			});
	}
	
	//删除留言
	static async deleteMessage(msg_id) {
	    return HttpHelper.delete(Config.api_detail_message_list, {
				data:{
					eid: Config.EID,
					access_token: Config.TOKEN || "",
					msg_id:msg_id,
				}
			});
	}
	// 回复留言
    static async replayMessage(params){
        return HttpHelper.post(Config.api_replay_message, {
            eid: Config.EID,
            access_token: Config.TOKEN || "",
            content:params.content,
            msg_id:params.msg_id,
            reply_id:params.reply_id,
            device:params.device,
            c_pic:params.c_pic,
        });        
    }
    // 置顶 or 公开留言
    static async setMsgControl(params){
        return HttpHelper.post(Config.api_msg_control, {
            eid: Config.EID,
            access_token: Config.TOKEN,
            msg_id:params.msg_id,
            fav:params.fav,
            top:params.top,
        });        
    } 
    // 绑定手机号    
    static async toBindPhone(params){
        return HttpHelper.post(Config.api_bind_phone, {           
            access_token: Config.TOKEN,
            phone:params.phone,
            code:params.code,            
        });     
    }
    // 解绑手机号
    static async toUnbindPhone(){
        return HttpHelper.post(Config.api_unbind_phone, {           
            access_token: Config.TOKEN,         
        });     
    }
    // 重置密码
    static async toResetPwd(params){
        return HttpHelper.post(Config.api_reset_pwd, {           
            access_token: Config.TOKEN,
            phone:params.phone,
            code:params.code,
            password:params.password,
            affirm_password:params.affirm_password,     
        });     
    }    
    // 换绑手机号
    static async toChangePhone(params){
        return HttpHelper.post(Config.api_change_phone, {           
            access_token: Config.TOKEN,
            phone:params.phone,
            code:params.code,
            old_password:params.old_password,
            source:'h5'
        });     
    }    
	static async getUserInfo() {
	    return HttpHelper.get(Config.api_get_userInfo, {
	        params:
	            {
	                access_token: Config.TOKEN,
	            }
	    });
	}
	
	static async getAuthInfo() {
		let url = Config.api_get_authInfo;
        let Renew = sessionStorage.getItem('Renew')
        let Auth_status = sessionStorage.getItem('Auth_status')
		if(Renew && (Auth_status == 2)){
			url = Config.api_get_renewInfo;
		}
		
	    return HttpHelper.get(url, {
	        params:
	            {
	                access_token: Config.TOKEN,
	                source: 'h5',
	            }
	    });
	}

    //暂存个人信息
	static async subAuthLog(info) {
		let parm = null;
        parm = info;
        parm.access_token = Config.TOKEN;
        parm.commit = 1;

		let isMobile = WxApi.isMobile();
		if(!isMobile){
			parm.source = 'pc';
		}

		let url = Config.api_auth_log;
		return HttpHelper.post(url, parm);
	}
	
	//保存企业组织公众号信息
	static async subAuthInfo(info,payType) {
		let parm = null;
		if(info.fromP){
			parm = {
				access_token: Config.TOKEN,
				full_name:info.full_name,
				legal_person:info.legal_person,
				license_no:info.license_no,
				license_pic:info.license_pic,
				type:info.type,
			}
		}else{
			 parm = info;
			 parm.access_token = Config.TOKEN;
			 parm.commit = 1;
		}
 		
		let isMobile = WxApi.isMobile();
		if(!isMobile){
			parm.source = 'pc';
		}
        
        if(!WxApi.isMobile() || !WxApi.isWeixn()){ //若为pc端，则调网页支付
            parm.pay_source = 'pc';
        }  
        
        if(!WxApi.isWeixn()){ //pc上打开才加此参数
            parm.from = 'enroll_h5_new';
        }

        if(payType == 'other'){ //代付  
            let url = Config.api_apply_replace;
            let Renew = sessionStorage.getItem('Renew');
            let Auth_status = sessionStorage.getItem('Auth_status');
            if(Renew && Auth_status == 2){
                url = Config.api_apply_renew_replace;
            }
            return HttpHelper.post(url, parm);
        }else{
            let url = Config.api_set_companyInfo;
            let Renew = sessionStorage.getItem('Renew')
            let Auth_status = sessionStorage.getItem('Auth_status')
            if(Renew && Auth_status == 2){
                url = Config.api_set_renewInfo;
            }
            return HttpHelper.post(url, parm);
        }
		
	}
	
	static async getCode(phone) {
	    return HttpHelper.post(Config.api_get_code, {
			access_token: Config.TOKEN,
			phone:phone,
	    });
	}
    // 免登录获取验证码
    static async getPwdCode(phone) {
	    return HttpHelper.post(Config.api_pwd_code, {		 
			phone:phone,
	    });
	}	
	//公众号认证验证码校验
	static async validateCode(code) {
		let url = Config.api_validate_code;
        let Renew = sessionStorage.getItem('Renew')
        let Auth_status = sessionStorage.getItem('Auth_status')
		if(Renew && Auth_status== 2){
			url = Config.api_validate_renewCode;
		}
	    return HttpHelper.post(url, {
			access_token: Config.TOKEN,
			code:code,
	    });
	}
	
	static async getPcPayResult(orderNo) {
	    return HttpHelper.get(Config.api_get_pc_payResult, {
	        params:
	            {
	                order_no: orderNo,
	                access_token: Config.TOKEN
	            }
	    });
	}
	
	//获取座位图信息
	static async getSeatInfo(skey) {
	    return HttpHelper.get(Config.api_seat_info, {
	        params:
	            {
	                eid: Config.EID,
	                access_token: Config.TOKEN,
					key: skey
	            }
	    });
	}

    //最近浏览列表
    static async getEnrollHistory(){
        return HttpHelper.get(Config.api_user_history,{
            params:
            {
                access_token: Config.TOKEN,
            }
        })
    }

    // 我管理的&我参与的
    static async getManageList(data){
        return HttpHelper.get(Config.api_manage_list,{
            params:
            {
                access_token: Config.TOKEN,
                type:data.type,
                page:data.page,
                count:data.count,
                cancel_status:data.cancel_status,
                keyword:data.keyword,
                time_out:data.time_out,
                status:data.status,
                is_create:data.is_create,
            }
        })
    }

    // 公开活动
    static async toPublicEnroll(data){
        return HttpHelper.post(Config.api_enroll_public,{
            access_token: Config.TOKEN,
            type:data.type,
            send_msg:data.send_msg,
            eid:data.eid
        })
    }

    // 个人主页详情
    static async getHomePageData(unionid){
        return HttpHelper.get(Config.api_homepage_info,{
          params:{
            access_token: Config.TOKEN,
            unionid,
          }
        })
    }

    // 个人主页详情（免登录）
    static async getHomePageV2(unionid){
        return HttpHelper.get(Config.api_homepage_info2,{
          params:{
            access_token: Config.TOKEN,
            unionid,
          }
        })
    }

    // 个人主页推荐列表
    static async recommendHomePage(){
        return HttpHelper.get(Config.api_common_homepage)
    }
    
    // 公开活动列表
    static async getEnrollList(data){
        return HttpHelper.get(Config.api_web_enroll,{
          params:{
            access_token: Config.TOKEN,
            page:data.page,
            count:data.count,
            unionid:data.unionid,
            keyword:data.keyword,
            type:data.type,
            home_type: data.home_type,
          }
        })
    }
    
	//增加消息订阅次数  type 1 获取数据  2增加订阅次数
    static async addSubNotice(type){ 
        const data = ['3VnoJn7ILmAeFH3wxCZyktTIbRNogLt_8ntfmFG5Jl0','l9GmcM9xfZOMWoorpD24LpiGr_oQf6ZGxdN08ooEWR0','E70ynK2JfAiMoRgGExzPfju2VGAXv9uwH-vWYa16yyg']
        let params = {
            access_token: Config.TOKEN,
        }
        if(type == 2){ //2增加订阅次数
            params.template_ids = data
            return HttpHelper.post(Config.api_sub_notice,params)
        }else{ //1是获取数据
            return HttpHelper.get(Config.api_sub_notice,{params})
        }
    }

    // 我关注的列表
    static async getSubList(data){
        return HttpHelper.get(Config.api_sub_list,{
          params:{
            access_token: Config.TOKEN,
            page:data.page,
            count:data.count
          }
        })
    }

    // 删除快速填写信息
    static async deleteExtraInfo(params){
        return HttpHelper.delete(Config.api_extra_info,{
            data:{
                access_token: Config.TOKEN,
                key:params.key,
                old_key:params.old_key,
            }
        })
    }

    // 新增 快速填写信息
    static async saveExtraInfo(params){
        return HttpHelper.post(Config.api_extra_info,{
            access_token: Config.TOKEN,
            key:params.key,
            old_key:params.old_key,
            name:params.name,
            value:params.value
        })
    }

    // 获取扫码登录的二维码
    static async getLoginCode(){
        return HttpHelper.get(Config.api_login_code)
    } 
    
    // 扫码登录
    static async scanCodeLogin(code){
        return HttpHelper.get(Config.api_pc_login,{
            params:{
                code
            }
        })
    }

    // 消息提醒
    static async switchNotice(params,type='send'){
        if(type == 'send'){
            return HttpHelper.post(Config.api_notice_switch,{
                access_token: Config.TOKEN,
               ...params
            })
        }else{
            return HttpHelper.get(Config.api_notice_switch,{
                params:{
                    access_token: Config.TOKEN,
                }
            })
        }
       
    }

    // 会员卡详情1
    static async vipCardDetail(id){
        return HttpHelper.get(Config.api_vip_card_detail,{
            params:{
                access_token:Config.TOKEN,
                card_id:id
            }
        })
    }

    // 会员卡列表 （从报名中申请）
    static async vipCardList(unionid){
        return HttpHelper.get(Config.api_card_list,{
            params:{
                access_token:Config.TOKEN,
                target_unionid:unionid
            }
        })
    }

    // 购买会员卡
    static async payVipCard(params){
        const data = {
            access_token:Config.TOKEN,
            card_id:params.card_id,
            phone:params.phone,
            user_name:params.user_name,
            union_key:params.union_key,
            code:params.code,
            id_card:params.id_card,
            address:params.address,
            sex:params.sex,
            day_type:params.day_type,
            birthday:params.birthday,
            cert_num: params.cert_num,
            from:'enroll_h5_new',           
        }        
        if(!WxApi.isMobile()){//非微信端，则加此参数，返回微信支付二维码
            data.source = 'pc'
        }       
        return HttpHelper.post(Config.api_vip_card_pay,data)
    }

    // 会员卡续费
    static async toRenewVip(params){   
        const data = {
            access_token:Config.TOKEN, 
            phone:params.phone,
            user_name:params.user_name,
            wx_no:params.wx_no,
            card_id:params.card_id, 
            vip_id:params.vip_id,
            code:params.code,
            id_card:params.id_card,
            address:params.address,
            sex:params.sex,
            day_type:params.day_type,
            birthday:params.birthday,
            cert_num: params.cert_num,
            from:'enroll_h5_new'                   
        }

        if(!WxApi.isMobile()){//非微信端，则加此参数，返回微信支付二维码
            data.source = 'pc'
        }       
        return HttpHelper.post(Config.api_vip_renew_pay,data)
    }

    // 查看会员详情
    static async getVipUserDetail(out_trade_no,vip_id){
        let  params = {
            access_token:Config.TOKEN,           
        }
        if(out_trade_no){
            params.out_trade_no = out_trade_no
        }
        if(vip_id){
            params.vip_id = vip_id
        }
        return HttpHelper.get(Config.api_user_vip_detail,{
            params
        })
    }

    // 会员卡购买记录
    static async getVipList(data){      
        return HttpHelper.get(Config.api_vip_order_list,{
           params:{
                access_token:Config.TOKEN, 
                count:data.count,
                vip_unionid:data.vip_unionid,
                card_id:data.card_id,
                page:data.page,     
                type: 'my'         
           }
        })
    }

    // 清除最近浏览记录
    static async delHistory(){
        return HttpHelper.post(Config.api_history_del,{
            access_token:Config.TOKEN,
        })
    }
    
    // 查看是否开启企业微信提醒
    static async getWorkConfig(){
        return HttpHelper.get(Config.api_work_config,{
            params:{
                access_token:Config.TOKEN,
            }
        })
    }

    // 注销账号
    static async toCancelUser(){
        return HttpHelper.post(Config.api_cancel_user,{
            access_token:Config.TOKEN,
        })
    }

    // h5获取ticket
    static async getShortTicket(data){
        return HttpHelper.get(Config.api_short_ticket,{
            params:{
                eid:data.eid,
            }
        })
    }

    // 获取用户信息
    static async getAuthMsg(id){
        return  HttpHelper.get(Config.api_get_authInfo,{
            params:{
                unionid:id,
                access_token:Config.TOKEN
            }
        })
    }

    // 手机号登录
    static async toPhoneLogin(params){
        return HttpHelper.post(Config.api_login_phone,{
            password:params.password,
            phone:params.phone,
            encrypt:1,
            from:"h5"
        })
    }

    //退款记录    
    static async getRefundList(info_id){
        return HttpHelper.get(Config.api_list_info,{
           params:{
                access_token:Config.TOKEN,
                info_id
           }
        })
    }

    // 退款订单详情
    static async getRefundDetail(data){
        return HttpHelper.get(Config.refund_detail,{
            params:{
                access_token:Config.TOKEN,
                info_id:data.info_id,
                out_refund_no:data.out_refund_no
            }
        })
    }
    
    //获取上次提交记录    
    static async getPeriodDetail(){
        return HttpHelper.get(Config.api_period_detail,{
            params:{
                access_token:Config.TOKEN,
                eid:Config.EID             
            }
        })
    }

    // 编辑个人信息
    static async editUserMessage(data,type){
        if(type == 'get'){
            return HttpHelper.get(Config.api_user_edit,{
                params:{
                    access_token:Config.TOKEN,                  
                }
            })
        }else{
            return HttpHelper.post(Config.api_user_edit,{
                access_token:Config.TOKEN,
                uname:data.uname,
                upic:data.upic,
                account_phone:data.account_phone,
                code:data.code
            })
        }
       
    }

    // 团购订单
    static async getCartOrder(data){
        return HttpHelper.get(Config.api_cart_orders,{
            params:{
                access_token:Config.TOKEN, 
                page: data.page,
                eid: Config.EID,
                count: data.count              
            }
        })
    }
    
    // 收款码付款    
    static async toCodePay(params){
        const data = {
            access_token: Config.TOKEN, 
            union_key: params.union_key,
            unionid: params.unionid,
            fee: params.fee,
            remark: params.remark,
            from:'enroll_h5_new',            
        }
        if(params.source){
            data.source = params.source;
        }
        if(params.eid){
            data.eid = params.eid;
        }
        if(params.referer){ //邀请人
            data.referer = params.referer;
        }
        return HttpHelper.post(Config.api_code_pay,data)
    }

    // 收款商户信息
    static async getCashierDetail(unionid,type,eid){
        const params = {
            access_token: Config.TOKEN,            
            unionid,          
        }
        if(type == 'reward'){
            params.source = 'reward'
            params.eid = eid
        }
        return HttpHelper.get(Config.api_cashier_detail,{
            params,
        })
    }

    // 未登录情况下查看商户收款码
    static getMerchantDetail(unionid){        
        return HttpHelper.get(Config.api_cashier_detail,{
            params: {unionid, source: 'h5'},
        })
    }

    // 我的优惠券列表
    static async getCouponList(data){
        return HttpHelper.get(Config.api_coupon_list,{
            params:{
                access_token: Config.TOKEN,                            
                page:data.page,
                count:data.count,
                coupon_unionid:data.coupon_unionid,   
            }
        })
    }

    // 代付链接详情   
    static async getReplaceInfo(data){
        return HttpHelper.get(Config.api_apply_replace_info,{
            params:{
                out_trade_no:data.out_trade_no,
                type:data.type
            }
        })
    }

    // 代付拉起支付
    static async applyOtherPay(out_trade_no){
        let parm = {
            access_token: Config.TOKEN, 
            out_trade_no: out_trade_no,
            from:'enroll_h5_new',            
        }
        if(!WxApi.isMobile() || !WxApi.isWeixn()){ //若为pc端，则调网页支付
            parm.pay_source = 'pc'
        }
        return HttpHelper.post(Config.api_apply_replace_pay,parm)
    }

    // 删除留言
    static async toDeleteMsg(msg_id,reply_id){
        return HttpHelper.post(Config.api_msg_del,{
            access_token: Config.TOKEN,
            eid:Config.EID,
            msg_id,
            reply_id
        })
    }

    // 查询用户是否能预约
    static searchMyEnroll(){
        return HttpHelper.get(Config.api_my_enroll,{
            params:{
                access_token: Config.TOKEN,
                user_joins:'all',
                page:1,
                count:10,
                eid:Config.EID
            }
        })
    }   
    
    // 生成分享SchemeUrl
    static getSchemeUrl(params){
        return HttpHelper.post(Config.api_scheme_url,{
            path:params.path,
            expire_time:params.expire_time,
            scene_id:params.scene_id
        })
    }    

    // 提交确认会员数据
    static toVerifyVip(params){
        return HttpHelper.post(Config.api_verify_vip,{
            access_token: Config.TOKEN,
            vip_id:params.vip_id,
            phone:params.phone,
            code:params.code
        })
    }    

    // 我加入的会员列表
    static getMyVipCard(){
        return HttpHelper.get(Config.api_my_join_vip,{
            params:{
                access_token: Config.TOKEN,
                page:1,
                count:100,
            }                  
        })
    }
    // 转让参与记录
    static toMoveInfo(info,info_id){
        return HttpHelper.post(Config.api_move_info,{
            access_token: Config.TOKEN,
            info_id,
            eid:Config.EID,
            info,
        })
    }
    // 用户标记数据分享
    static toShareInfo(data){
        const params = {
            access_token: Config.TOKEN,
            info_id: data.info_id,          
        }
        if(data.share_type){
            params.share_type = data.share_type;
        }
        return HttpHelper.post(Config.api_info_share, params)
    }
    
    // 钉钉消息配置
    static getDingConfig(){
        return HttpHelper.get(Config.api_ding_webhook,{
            params:{
                access_token: Config.TOKEN              
            }                  
        })
    }
    // 获取编辑历史记录
    static getEditHistory(data){
        return HttpHelper.get(Config.api_edit_list,{
            params:{
                access_token: Config.TOKEN,
                info_id: data.info_id,   
                page: data.page,
                count: data.count,
            }                  
        })
    }

    // 免登录查看付款价格配置
    static getPriceList(unionid){
        return HttpHelper.get(Config.api_price_list,{
            params:{
                unionid
            }                  
        })
    }

    // 回收站列表
    static getBackList(data){        
        return HttpHelper.get(Config.api_bak_list,{
            params:{
                access_token: Config.TOKEN,
                page: data.page,
                count: data.count,
            }                  
        })
    }
    // 恢复活动
    static toRecoverEnroll(eid){
        return HttpHelper.post(Config.api_recover_enroll,{
            access_token: Config.TOKEN,
            eid,          
        })
    }
    // 彻底删除活动
    static toDeleteEnroll(eid){
        return HttpHelper.post(Config.api_direct_del,{
            access_token: Config.TOKEN,
            eid,          
        })
    }
    // 授权code，获取openid
    static getOpenid(data){
        return HttpHelper.get(Config.api_ext_openid,{
            params:{
                app_id: data.appid,             
                code: data.code,
            }                  
        })        
    }
    // 测试下单
    static toPayTest(data){   
        return HttpHelper.post(Config.api_pay_test,{
            app_id: data.appid,                         
            openid:data.openid,
            pay_type:data.pay_type,                            
        })        
    }    
    // 注册签名(测试支付，无token)
    static toPaySign(data){   
        return HttpHelper.post(Config.api_pay_sign,{             
            app_id:data.appid,                         
            url:data.url,                                
        })        
    }
    // 核销签到
    static toCheckCert(data){           
        if(data.eid){
            return HttpHelper.get(Config.api_cert_check,{ //查看项目报名签到信息          
                params:{
                    access_token: Config.TOKEN,                        
                    eid:data.eid,   
                }                             
            })    
        }else{
            const params = { //去签到         
                access_token: Config.TOKEN,                        
                info_id:data.info_id                          
            }
            if(data.items && data.items.length){ //多个预约时间单独签到
                params.items = data.items;
            }
            return HttpHelper.post(Config.api_cert_check,params)  
        }           
    }
    // 验证手机号和验证码
    static checkPhone(data){
        const params = {
            access_token: Config.TOKEN,
            phone: data.phone,
            code: data.code,
        }
        return HttpHelper.post(Config.checkPhoneCode,params)  
    }
    // vip会员登录
    static vipToLogin(data){
        const params = {
            access_token: Config.TOKEN,
            unionid: data.unionid,
        }
        return HttpHelper.post(Config.vipLogin,params)  
    }
    // 订单投诉
    static orderComplaint(params,type){
        if(type == 'get'){
            return HttpHelper.get(Config.api_order_complaint,{
                params:{
                    access_token: Config.TOKEN,            
                    out_trade_no: params.out_trade_no,  
                }              
            }) 
        }else {
            return HttpHelper.post(Config.api_order_complaint,{
                access_token: Config.TOKEN,
                reason: params.reason,
                content: params.content,
                out_trade_no: params.out_trade_no,
                pics: params.pics,
                phone: params.phone
            }) 
        }        
    }
    // 撤销投诉详情
    static cancelComplaint(params){
        return HttpHelper.post(Config.api_complaint_cancel,{
            access_token: Config.TOKEN,
            complaint_id: params.complaint_id,
            grant: params.grant,           
        })  
    }
    // 获取所有的消息通知
    static getNoticeAll(eid){
        return HttpHelper.get(Config.api_notice_all,{
           params: {
            access_token: Config.TOKEN,
            eid      
           }  
        }) 
    }
    // 最近六个月参与记录
    static getJoinList(data){
        return HttpHelper.get(Config.api_join_list,{
            params: {
                access_token: Config.TOKEN,
                page: data.page,
                count: data.count,
                unionid: data.unionid,
                owner_unionid: data.owner_unionid,  
            }  
        }) 
    }

    // 转载活动
    static reshippedEnroll(params){
        return HttpHelper.post(Config.api_reshipped_enroll,{
            access_token: Config.TOKEN,
            type: params.type,
            eid: params.eid,           
        })  
    }

    // 邀请参团
    static toShareGroup(data){
        return HttpHelper.get(Config.api_group_share,{
            params: {
                access_token: Config.TOKEN,
                eid: Config.EID,
                tid: data.tid,
                type: data.type,      
            }  
         }) 
    }
    // 转让记录
    static toShareGroup(data){
        return HttpHelper.get(Config.api_group_share,{
            params: {
                access_token: Config.TOKEN,
                eid: Config.EID,
                tid: data.tid,
                type: data.type,      
            }  
         }) 
    }
    // 分享记录
    static getShareList(data){
        return HttpHelper.get(Config.api_share_list,{
            params: {
                access_token: Config.TOKEN,
                eid: Config.EID,
                count: data.count,
                page: data.page,      
            }  
        }) 
    }
    // 获取共创团队列表
    static getTeamUser(data){
        return HttpHelper.get(Config.api_team_user,{
            params: {
                access_token: Config.TOKEN,
                page: data.page,
                count: data.count,
                unionid: data.unionid,
                source: 'h5'
            }
        })
    }    
    // 分享口令
    static getSearchToken(){
        return HttpHelper.get(Config.api_search_token,{
            params: {
                access_token: Config.TOKEN,
                eid: Config.EID,
            }
        })
    }   
    // 发送投诉留言
    static sendComplaintMsg(params){
        return HttpHelper.post(Config.api_complaint_msg,{
            access_token: Config.TOKEN,
            out_trade_no: params.out_trade_no,
            content: params.content,
            pics: params.pics,
        })
    }
    // 投诉留言列表
    static getComplaintList(data){
        return HttpHelper.get(Config.api_complaint_list,{
            params: {
                access_token: Config.TOKEN,
                out_trade_no: data.out_trade_no,
                page: data.page,
                count: data.count,
            }
        })
    }

    // 订单详情
    static getOrderDetail(data){        
        return HttpHelper.get(Config.api_order_detail,{
            params: {
                access_token: Config.TOKEN,
                out_trade_no: data.out_trade_no,               
                eid: Config.EID,
                unionid: data.unionid     
            }
        }) 
    }
    // 商户信息详情
    static getMerchantMsg(){
        return HttpHelper.get(Config.api_merchant_apply, {
            params: {
                access_token: Config.TOKEN,
            }
        })
    }
    // 查询进行中活动的数量
    static getEnrollNum(){
        return HttpHelper.get(Config.api_enroll_num, {
            params: {
                access_token: Config.TOKEN,
            }
        })
    }
    // 一键截止所有活动
    static closeEnrollAll(){
        return HttpHelper.post(Config.api_close_enroll, {
            access_token: Config.TOKEN,
        })
    }
    // VIP权益转让
    static authReplace(unionid){
        return HttpHelper.post(Config.api_auth_replace, {
            access_token: Config.TOKEN,
            new_unionid: unionid,
        })
    }
    // 管理员记录
    static adminHistory(){
        return HttpHelper.get(Config.api_admin_history, {
            params: {
                access_token: Config.TOKEN,
            }
        })
    }
    // 转让商户
    static toBindMch(params){
        return HttpHelper.post(Config.api_bind_mch, {
            access_token: Config.TOKEN,
            unionid: params.unionid,
            type: params.type,
            mch_id: params.mch_id,
        })
    }
    // 商户转移记录
    static getMchLog(params){
        return HttpHelper.get(Config.api_mch_rep_log, {
            params: {
                access_token: Config.TOKEN,
                page: params.page,
                count: params.count,
                mch_id: params.mch_id,
            }
        })
    }
    // 仅微信群聊可参与
    static getShareGroup(eid){
        return HttpHelper.get(Config.api_share_group, {
            params: {
                access_token: Config.TOKEN,
                eid,
            }
        })
    }
    //VIP购买的订单记录
    static getAuthOrder(unionid){
        return HttpHelper.get(Config.api_auth_order, {
            params: {
                access_token: Config.TOKEN,  
                unionid          
            }
        })        
    }
    // 回收站中恢复某个活动的数据
    static toBakPay(eid){
        const params = {
            access_token: Config.TOKEN,
            eid,
        }
        if(!WxApi.isMobile() || !WxApi.isWeixn()){ //若为pc端，则调网页支付
            params.pay_source = 'pc'
        }
        return HttpHelper.post(Config.api_bak_pay, params)
    }
    // 账号安全校验code
    static getRiskCode(unionid){
        return HttpHelper.get(Config.api_risk_qrcode, {
            params: {                
                unionid          
            }
        })   
    }    
    // 回收站复制创建活动
    static getCopyPay(eid){
        const params = {
            access_token: Config.TOKEN,
            eid,
        }
        if(!WxApi.isMobile() || !WxApi.isWeixn()){ //若为pc端，则调网页支付
            params.pay_source = 'pc'
        }
        return HttpHelper.post(Config.api_copy_pay, params)   
    }
    // 获取黑名单详情
    static getBlackDetail(unionid){
        return HttpHelper.get(Config.api_black_detail, {
            params: {                
                unionid          
            }
        })
    }
    // 提交账号申诉
    static toAppeal(params){
        return HttpHelper.post(Config.api_black_appeal, {
            access_token: Config.TOKEN,
            unionid: params.unionid,
            name: params.name,
            phone: params.phone,
            reason: params.reason,
            id_card_pic: params.id_card_pic,
            cert_pic: params.cert_pic
        })
    }
    // 获取申诉详情
    static getAppealDetail(unionid){
        return HttpHelper.get(Config.api_appeal_detail, {
           params: {unionid}
        })        
    }
    // 白名单校验
    static toCheckWhite(data){
        return HttpHelper.post(Config.api_check_white, {
            access_token: Config.TOKEN,
            eid: Config.EID,
            info: data,
        })        
    }
    // 付款记录
    static getRecordPay(data){
        return HttpHelper.get(Config.api_record_pay, {
            params: {
                access_token: Config.TOKEN,
                page: data.page,
                count: data.count,
                type: data.type,
            }
        })
    }
    // 订单投诉列表
    static getMyComplaintList(data){
        return HttpHelper.get(Config.api_my_complaint_list, {
            params: {
                access_token: Config.TOKEN,
                page: data.page,
                count: data.count,
            }
        })
    }

    // 商户投诉列表
    static getComplaintData(data){
        return HttpHelper.get(Config.api_my_complaint, {
            params: {
                access_token: Config.TOKEN,
                page: data.page,
                count: data.count,
            }
        })
    }

    // 商户投诉详情
    static getComplaintHistory(complaint_log_id){
        return HttpHelper.get(Config.api_complaint_history, {
            params: {
                access_token: Config.TOKEN,
                complaint_log_id,       
            }
        })        
    }
    
    // 新用户提交时保存头像昵称
    static saveNewUser(data){
        return HttpHelper.post(Config.api_save_new, {
            access_token: Config.TOKEN,              
            uname: data.uname,
        })
    }

    // 导出pdf数据
    static getPdfImg(info_id){
        return HttpHelper.post(Config.api_pdf_export, {
            access_token: Config.TOKEN,              
            eid: Config.EID,
            info_id
        })        
    }

    // 商户申请
    static getMechData(unionid){
        return HttpHelper.get(Config.api_mch_apply, {
            params: {
                access_token: Config.TOKEN,              
                unionid
            }
        })
    }
    // 获取钱包金额
    static getBalance(){
        return HttpHelper.get(Config.api_balance, {
            params: {
                access_token: Config.TOKEN,               
            }
        })
    }
    // 获取风险投诉数据
    static getMerchantStat(){
        return HttpHelper.get(Config.api_merchant_stat, {
            params: {
                access_token: Config.TOKEN,               
            }
        })
    }
    
}