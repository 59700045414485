import React ,{lazy,Suspense}from "react";
import {BrowserRouter as Router,BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
 
import store from "./store";
import {ActivityIndicator, Toast} from 'antd-mobile';
 
import {Provider, inject, observer} from "mobx-react";
import Config from "./config"
import DevTools from 'mobx-react-devtools';
import { Detail,Group,HomePage,Auth } from "./pages";
import { browserHistory } from 'react-router'

Config.on_error_interface = (msg) => {
    Toast.fail(msg);
};


@inject(["loadingStore"]) // 注入对应的store
@observer
class Loading extends React.Component {
	//toast text="正在加载" animating={this.props.loadingStore.isLoading}
    render() {
        return (
            <ActivityIndicator />
        );
    }
}



const routes = [
    {path: '/group', component:  Group},
    {path: '/detail', component: Detail},
    {path: '/transfor', component: lazy(() => import ('./pages/detail/transfor'))},
    {path: '/myreserve', component: lazy(() => import ('./pages/enroll/reserve'))},
    {path: '/error/:error_id', component: lazy(() => import ('./pages/global/error'))},
    {path: '/account', component: lazy(() => import ('./pages/account'))},
    {path: '/enrollList', component: lazy(() => import ('./pages/enroll/list'))},
    {path: '/enrollForm', component:  lazy(() => import ('./pages/enroll'))},
    {path: '/wirterPage/:key', component: lazy(() => import ('./components/DataEntry/wirter'))},
    {path: '/cert', component: lazy(() => import ('./pages/enroll/cert'))},
    {path: '/enrollGroupForm', component: lazy(() => import ('./pages/groupEnroll'))},
    {path: '/groupCert', component: lazy(() => import ('./pages/groupEnroll/groupCert'))},
    {path: '/report', component: lazy(() => import ('./pages/report/index'))},
    {path: '/complaint', component: lazy(() => import ('./pages/report/complaint'))},
    {path: '/complaintList', component: lazy(() => import ('./pages/report/complaintList'))},
    {path: '/sendMsg',component: lazy(() => import('./pages/report/sendMsg'))},
	{path: '/message', component: lazy(() => import ('./pages/message'))},
	{path: '/add', component: lazy(() => import ('./pages/message/add'))},
	{path: '/myMessage', component: lazy(() => import ('./pages/message/myList'))},
	{path: '/auth', component:Auth},
	{path: '/company', component: lazy(() => import ('./pages/auth/company'))},
	{path: '/personal', component: lazy(() => import ('./pages/auth/personal'))},
	{path: '/notice', component: lazy(() => import ('./pages/notice/notice'))},
	{path: '/seat', component: lazy(() => import ('./pages/enroll/seat'))},
	{path: '/webLink', component: lazy(() => import ('./pages/webLink/weblik'))},
    {path:'/recycleBin',component: lazy(() => import('./pages/myEnroll/recycleBin/recycleBin'))},
    {path:'/manage',component:lazy(() => import ('./pages/manage'))},
    {path:'/h',component:HomePage},
    {path:'/help',component:lazy(() => import ('./pages/userCenter/help'))},
    {path:'/personalInfo',component:lazy(() => import ('./pages/userCenter/personalInfo'))},
    {path:'/addMsg',component:lazy(() => import ('./pages/userCenter/personalInfo/addMsg'))},
    {path: '/payOrder', component:lazy(() => import('./pages/userCenter/order'))},
    {path: '/appeal', component:lazy(() => import ('./pages/userCenter/appeal/index'))},
    {path: '/addAppeal', component:lazy(() => import ('./pages/userCenter/appeal/addAppeal'))},
    {path:'/searchEnroll',component:lazy(() => import ('./pages/homePage/searchEnroll'))},
    {path:'/recommend',component:lazy(() => import ('./pages/homePage/recommend'))},
    {path:'/apply-vip',component:lazy(() => import ('./pages/homePage/applyVip'))},
    {path:'/vip-detail',component:lazy(() => import ('./pages/homePage/applyVip/vipDetail'))},
    {path:'/renew-vip',component:lazy(() => import ('./pages/homePage/applyVip/renewVip/renewVip'))},
    {path: '/bind-vip',component:lazy(() => import ('./pages/homePage/bindVip'))},
    {path: '/writeoff',component:lazy(() => import ('./pages/homePage/writeoff'))},
    {path: '/auth-message',component:lazy(() => import ('./pages/homePage/authMessage'))},
    {path: '/transfer',component:lazy(() => import ('./pages/auth/transfer/transfer'))},
    {path: '/mchTransfer',component:lazy(() => import ('./pages/auth/transfer/mchTransfer'))},
    {path: '/transferHistory',component:lazy(() => import ('./pages/auth/transfer/transferHistory'))},
    {path:'/my-vipcard',component:lazy(() => import ('./pages/homePage/myVipCard'))},
    {path: '/login',component:lazy(() => import ('./pages/userCenter/login'))},
    {path:'/del-account',component:lazy(() => import ('./pages/userCenter/personalInfo/delAccount'))},
    {path:'/refund-list',component:lazy(() => import ('./pages/refund/refundList/index'))},
    {path:'/refund-detail',component:lazy(() => import ('./pages/refund/refundDetail/index'))},
    {path:'/order-detail',component:lazy(() => import ('./pages/refund/orderDetail/index'))},
    {path:'/voucher',component:lazy(() => import ('./pages/enroll/voucher/voucher'))},
    {path:'/user-message',component:lazy(() => import ('./pages/userCenter/userMessage/index'))},
    {path:'/group-orders',component:lazy(() => import ('./pages/group/groupOrders/index'))},
    {path:'/web-pay',component:lazy(() => import ('./pages/webPay/pay'))},
    {path:'/custom-pay',component:lazy(() => import ('./pages/webPay/customPay/customPay'))},
    {path:'/custom-auth',component:lazy(() => import ('./pages/webPay/customAuth/customAuth'))},
    {path:'/other-pay',component:lazy(() => import ('./pages/auth/otherPay/pay'))},
    {path:'/auth-detail',component:lazy(() => import ('./pages/auth/authDetail/authDetail'))},
    {path:'/team-personal',component:lazy(() => import ('./components/DataEntry/team/teamPerson'))},
    {path:'/editor-history',component:lazy(() => import ('./pages/enroll/editorHistory/index'))},
    {path:'/bind-phone',component:lazy(() => import ('./pages/userCenter/bindPhone/bindPhone'))},
    {path:'/share',component:lazy(() => import ('./pages/global/share/share'))},
    {path:'/guide',component:lazy(() => import ('./pages/userCenter/login/guide/guide'))},
    {path: '/reward',component:lazy(() => import ('./pages/webPay/reward/reward'))},
    {path: '/sign',component:lazy(() => import ('./pages/webLink/webSign/webSign'))},
    {path: '/signature',component:lazy(() => import ('./pages/webLink/signature/index'))},
    {path: '/paycode', component:lazy(() => import ('./pages/payCode/index'))},
    {path: '/merchant', component:lazy(() => import ('./pages/merchant/index'))},
    {path: '/uploadfile',component:lazy(() => import ('./pages/webLink/uploadFile/index'))},
    {path:'/page-404',component:lazy(() => import ('./pages/page-404'))},
];


let App = ''
let href = window.location.href
let ua = navigator.userAgent.toLowerCase()
// 监听地址中带#，则重定向
// if(!/HeadlessChrome/.test(window.navigator.userAgent))
if(href.indexOf('eid') != -1 && href.indexOf('#group')!=-1){ //解决iphone,重定向导致签名失败 团购
    href = href.replace('#group','')
    let routerArr = href.split('?')
    window.location.href = routerArr[0]+'group?'+routerArr[1]
}else if(href.indexOf('eid') != -1 && href.indexOf('detail') == -1 && href.indexOf('group') == -1 && href.indexOf('share') == -1 && href.indexOf('writeoff') == -1 && href.indexOf('voucher') == -1){//解决iphone,重定向导致签名失败
    let routerArr = href.split('?')
    window.location.href = routerArr[0]+'detail?'+routerArr[1]
}else if(href.indexOf('#auth') != -1){ //#auth
    const url = href.replace('#','')
    window.location.href = url
}else if(href.indexOf('#/') != -1){ //监听地址栏中有#，则重定向  #/detail
    const url = href.replace('#/','')
    window.location.href = url
}else if(href.indexOf('#') != -1){   //#group
    const url = href.replace('#','/')
    window.location.href = url
} else{
    App = (props, context) =>
    (
        <Provider  {...store}>
             <Suspense  fallback={<div className="page-loading-text">加载中....</div>}>
                {/* basename='/test' */}
                <Router>
                    <div className="container">
                        <Switch>

                            {
                                routes.map(route => (
                                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component}></Route>
                                ))
                            }
                            <Redirect from='/' to={`/detail?eid=${Config.EID}&referer=${Config.referer}`}/>
                        </Switch>
                        {/*<DevTools position={{top: 0, left: 50, right: "60%"}}/>*/}
                    </div>
                </Router>
            </Suspense>
        </Provider>
    );
}

export default App;	